/* common styles start */

.full-width {
	max-width: 1920px;
	margin: 0 auto;
	width: 100%;
} 	
.restrict-width {
	max-width: 1400px;
	margin: 0 auto;
	width: 100%;
}

.padding-lr {
	padding: 0 40px;
	@include layout(1024) {
		padding: 0 30px;
	}
	@include layout(1023) {
		padding: 0 20px;
	}
	@include layout(767) {
		padding: 0 0px;
	}
}
.mr-left-0 {
	margin-left: 0 !important;
}

.mr-right-0 {
	margin-right: 0 !important;
}

.pad-left-0 {
	padding-left: 0 !important;
}

.pad-right-0 {
	padding-right: 0 !important;
}

.verticalM {
	display: inline-block;
	vertical-align: middle;
}

.verticalT {
	display: inline-block;
	vertical-align: top;
}

.verticalB {
	display: inline-block;
	vertical-align: bottom;
}

.hide {
	display: none;
}

.show {
	display: inline-block;
}

.v-hidden {
	visibility: hidden;
}

.v-show {
	visibility: visibility;
}

button {
	appearance: none;
	outline: none;
	border: none;
	font-family: inherit;
	line-height: 1;
	cursor: pointer;
}

.buttons-block {
	display: flex;
}

.btn-fill {
	color: #fff;
	font-size: 14px;
	background-color: #71afe0;
	padding: 12px 35px;
	border-radius: 4px;
}

.btn-text {
	color: #71afe0;
	font-size: 14px;
	background-color: transparent;
	margin-left: 20px;
}


/* custom checkbox styles start */

.checkbox-group {
	display: flex;
	flex-wrap: wrap;
	padding-top: 15px;
}

.checkbox-block {
	margin-bottom: 20px;
	width: 250px;
	span {
		display: inherit;
		padding-left: 26px;
		color: #929ba5;
		font-size: 12px;
	}
}

.checkbox-label {
	color: #504b4a;
	font-size: 16px;
}

input[type="checkbox"] {
	width: inherit;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
	position: absolute;
	left: -9999px;
}

[type="checkbox"]:not(:checked)+label,
[type="checkbox"]:checked+label {
	position: relative;
	padding-left: 1.6em;
	cursor: pointer;
	line-height: 14px;
}


/* checkbox aspect */

[type="checkbox"]:not(:checked)+label:before,
[type="checkbox"]:checked+label:before {
	content: url('../images/checkbox-1.svg');
	position: absolute;
	left: 0;
	top: 0;
	width: 14px;
	height: 14px;
	/* width: 1.25em; height: 1.25em;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1); */
}


/* checked mark aspect */

[type="checkbox"]:not(:checked)+label:after,
[type="checkbox"]:checked+label:after {
	content: url('../images/checkbox-2.svg');
	position: absolute;
	top: 0;
	left: 0;
	width: 14px;
	height: 14px;
	transition: all .2s;
}


/* checked mark aspect changes */

[type="checkbox"]:not(:checked)+label:after {
	opacity: 0;
	transform: scale(0);
}

[type="checkbox"]:checked+label:after {
	opacity: 1;
	transform: scale(1);
}


/* custom checkbox styles end */


/* common styles end */

.m-t-hide {
	display: block;
	@include layout(1023) {
		display: none;
	}
}

.m-t-show {
	display: none;
	@include layout(1023) {
		display: block;
	}
}
.common-footer {
  overflow: hidden;
}