.main-testimonal {
	overflow: hidden;
	margin: 140px auto;
	position: relative;
	@include layout(767) {
		padding: 35px 20px 60px 20px;
		margin-bottom: 65px;
		margin-top: 90px;
	}
}

.background-shadow-in-mobile {
		position: absolute;
		z-index: -1;
		height: 98%;
		background: linear-gradient(180deg, #eaedff 0%, #e6e7ef 100%);
		width: 1920px;
		top: 0;
		border-radius: 50%;
		left:calc((100% - 1920px) / 2);
		background-position: center;
		display: block;

		@include layout(767){
			transform: rotate(0deg);
			height: 100%;
		}
}

.testimonal-block {
	padding-top: 115px;
	padding-bottom: 85px;
	text-align: center;
	margin: 40px auto;
	line-height: 23px;
	max-width: 450PX;
	width: 100%;
	@include layout(767) {
		margin: 20px auto;
		padding: 30px 0px;
	}
	.para-header {
		margin-bottom: 20px;
	}
	.para-header {
		@include layout(767) {
			font-size: 20px;
		}
	}
	hr {
		margin: auto;
		background: #ff6600;
		margin-top: 1px;
		width: 100px;
		height: 4px;
		margin-bottom: 60px;
		border: 1px solid #ff6600;
		@include layout(767){
			height: 2px;
		}
	}
	.quotationmark-block-one {
		margin-left: -40px;
		text-align: left;
			img{
			width: 24px;
	}
		@include layout(580) {
			margin-left: 0px;
		}
	}
	.quotationmark-block-two {
		margin-right: -40px;
		text-align: right;
			img{
			width: 24px;
			}
		@include layout(580) {
			margin-right: 0px;
		}
	}
	.text-block {
		font-family: $rob-reg;
		color: #333333;
		font-size: 22px;
		line-height: 28px;
		text-align: center;
		@include layout(767) {
			font-size: 14px;
		}
		@include layout(580) {
			font-size: 14px;
			padding: 0px 25px;
		}
	}
	.person-name-block {
		font-family: $lat-bld;
		color: #333333;
		font-size: 18px;
		line-height: 20px;
		text-align: center;
	}
	.person-job {
		font-family: $lat-reg;
		color: #7c7b7b;
		font-size: 16px;
		line-height: 20px;
		text-align: center;
	}
}

.user-profiles-blocks {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	.slick-current {
		width: 77px !important;
		height: 77px;
	}
}

.user-img-wapper {
	width: 55px !important;
	height: 55px;
	border-radius: 50%;
	border: 1px solid rgba(0, 0, 0, 0.1);
	margin-right: 40px;
	&:last-child {
		margin-right: 0px;
	}
	&.active {
		width: 79px !important;
		height: 79px;
		border: 1px solid #ff6600;
	}
	img {
		width: 100%;
		border-radius: 50%;
	}
}
