.common-footer-block {
	display: flex;
	background: linear-gradient(180deg, #eaedff 0%, #e6e7ef 100%);
	justify-content: space-between;
	max-height: 450px;
	position: relative;
}

.contact-us-block {
	padding: 70px 0px 0px 30px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	@include layout(767) {
		padding: 50px 20px 0px 20px;
	}
	.text-header {
		color: #383838;
		font-size: 22px;
		font-weight: 600;
		line-height: 22.934px;
		text-align: left;
		@include layout(767) {
			font-size: 16px;
		}
	}
	.social-icons-block-in-footer {
		display: flex;
		margin-top: 30px;
	}
	.each-social-block {
		width: 36px;
		cursor: pointer;
		height: 36px;
		margin-right: 30px;
		img {
			border-radius: 50%;
			width: 100%;
		}
	}
	.para-header {
		margin-bottom: 10px;
		@include layout(767) {
			font-size: 18px;
		}
	}
	.text-block {
		font-family: Lato;
		color: #383838;
		font-size: 18px;
		font-weight: 400;
		line-height: 28px;
		text-align: left;
		@include layout(767) {
			font-size: 14px;
			line-height: 20px;
		}
	}
	.under-line {
		//margin: auto;
		background: #ff6600;
		height: 4px;
		margin-top: 1px;
		width: 100px;
		border: 1px solid #ff6600;
		margin-bottom: 60px;
		@include layout(767) {
			margin-bottom: 20px;
			width: 50px;
			height: 2px;
		}
	}
}

.map-block {
	width: 50%;
	img {
		border-top-left-radius: 70%;
		float: right;
	}
	@include layout(767) {
		display: none;
	}
}
.design-brand{
	margin-top: 30px;
	font-size: 12px;
	margin-bottom: 20px;
	a{
		padding-left: 5px;
	}
}
.divami-logo {
    width: 45px;
    margin-top: 10px;
}