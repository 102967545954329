//about section styles
.desc-comp-one {
	text-align: center;
	margin: 0 auto;
	max-width: 900px;
	width: 100%;
	margin-top: 60px;
	line-height: 23px;
	@include layout(1023) {
		padding: 0px 30px;
	}
	@include layout(767) {
		margin-top: 100px;
		padding: 0px 20px;
	}
	.para-header {
		margin-bottom: 20px;
		@include layout(767) {
			font-size: 20px;
		}
	}
	hr {
		margin: auto;
		background: #ff6600;
		margin-top: 1px;
		width: 100px;
		height: 4px;
		border: 1px solid #ff6600;
		margin-bottom: 55px;
		@include layout(767) {
			width: 50px;
			margin-bottom: 20px;
			height: 2px;
		}
	}
	.text-block-desktop {
		color: #7b7c7c;
		font-size: 16px;
		font-family: $lat-reg;
		@include layout(767) {
			display: none;
		}
	}
	.text-block-mobile {
		color: #7b7c7c;
		font-size: 14px;
		font-family: $lat-reg;
		padding: 0px 20px;
		@include minLayout(767) {
			display: none;
		}
	}
}

.para-header {
	color: #172899;
	font-size: 36px;
	font-family: $rob-reg;
}

.desc-comp-two {
	text-align: center;
	position: relative;
	margin: 60px auto 0px auto;
	padding-top: 115px;
	padding-left: 15%;
	padding-right: 15%;
	background-size: 140%;
	overflow:hidden;
	padding-bottom: 130px;
	background-repeat: no-repeat;
	background-position: center;
	line-height: 23px;
	@include layout(1250) {
		padding-left: 10%;
		padding-right: 10%;
	}
	@include layout(1200) {
		background-size: 170%;
	}
	@include layout(850) {
		background-size: 180%;
	}
	@include layout(767) {
		margin: 100px 0px 60px 0px;
		padding: 65px 20px;
	}
	.para-header {
		margin-bottom: 20px;
		@include layout(767) {
			font-size: 20px;
		}
	}
	hr {
		margin: auto;
		background-color: #ff6600;
		width: 100px;
		border: 1px solid #ff6600;
		height: 4px;
		margin-top: 1px;
		margin-bottom: 60px;
		@include layout(767) {
			width: 50px;
			margin-bottom: 20px;
			height: 2px;
		}
	}
	.text-header {
		font-family: $lat-reg;
		color: #333232;
		font-size: 24px;
		margin-bottom: 15px;
		position: relative;
		left: -15px;
		text-align: left;
		@include layout(767) {
			font-size: 18px;
		}
	}
	ul {
		text-align: left;
		list-style: disc;
		margin-bottom: 18px;
		line-height: 26px;
	}
	.details-block {
		display: flex;
		justify-content: space-between;
		color: #7b7c7c;
		font-size: 16px;
		font-family: $lat-reg;
		align-items: center;
		@include layout(767) {
			font-size: 14px;
			flex-wrap: wrap;
			flex-direction: column-reverse;
		}
		.image-block {
			img {
				@include layout(767) {
					width: 100%;
				}
			}
		}
	}
	.details-block:nth-child(even) {
		margin-top: 25px;
		@include minLayout(767) {
			flex-direction: row-reverse;
		}
	}
	.list-block {
		margin-right: 40px;
		;
		@include layout(1023) {
			margin-left: 30px;
		}
		@include layout(767) {
			margin: 15px 0px 0px 20px;
			align-self: baseline;
		}
	}
	.image-block {
		margin-left: -30px;
		@include layout(1023) {
			width: 50%;
			img {
				width: 100%;
			}
		}
		@include layout(767) {
			width: 100%;
			margin-left: 0px;
		}
	}
}
