/* header styles start */

.logo-and-menu-section {
	padding: 30px 70px 0px 70px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@include layout(767) {
		padding: 30px 50px 0px 20px;
	}
	.header-right-sdie-login-button{
		z-index: 5;
	}
	.get-touch-button{
		margin-top: 0;
		a{
			padding: 12px 25px;
			width: auto;
			height: auto;
			@include layout(767){
				padding: 10px 20px;
			}
		}
	}
			.get-touch-text{
				padding-right: 0px;
			}  
}

.header-logo-section {
	img {
		height: 100px;
		cursor: pointer;
		@include layout(767) {
			width: 85px;
			height: 51px;
		}
	}
}

/* header styles end */