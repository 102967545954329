.get-touch-section {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	top: -50px;
    width: 100%;
    padding: 0px 80px;
    @include layout(1280) {
        padding: 0px 50px;
	}
	@include layout(1023) {
		flex-wrap: wrap-reverse;
		position: initial;
        justify-content: center;
        padding: 0px 30px;
    }
    @include layout(767) {
        padding: 0px;
	}
}

.para-header {
	@include layout(767) {
		font-size: 20px;
	}
}

.arrow-button {
	img {
		width: 14px;
		height: 12px;
	}
}

.img-section {
	overflow: hidden;
	img {
		@include layout(767) {
			width: 125%;
    		margin-left: -41px
		}
		@include layout(420) {
			width: 120%;
		}
	}
}

.text-and-button-section {
	position: relative;
	@include layout(767) {
		text-align: center;
		padding: 0px 20px;
	}
}

.fingertrips-text-desktop {
	color: #172899;
	font-size: 24px;
	font-family: $rob-reg;
	@include layout(767) {
		display: none;
	}
}

.fingertrips-text-mobile {
	color: #172899;
	font-size: 16px;
	;
	font-family: $rob-reg;
	@include minLayout(767) {
		display: none;
	}
}

.intelliscan-title-text-desktop {
	color: #172899;
	font-size: 55px;
	font-family: $rob-reg;
	b {
		color: #ff6600;
	}
	@include layout(767) {
		display: none;
	}
}

.intelliscan-title-text-mobile {
	color: #172899;
	font-size: 23px;
	font-family: $rob-reg;
	b {
		color: #ff6600;
	}
	@include minLayout(767) {
		display: none;
	}
}

.intelliscan-sub-text-desktop {
	color: #636469;
	font-size: 20px;
	padding-top: 2px;
	@include layout(767) {
		display: none;
	}
}

.intelliscan-sub-text-mobile {
	color: #636469;
	font-size: 14px;
	padding-top: 2px;
	@include minLayout(767) {
		display: none;
	}
}

.get-touch-button {
	border-radius: 6px;
	background-color: #172899;
	margin-top: 30px;
	cursor: pointer;
	display: inline-block;
	a {
		display: flex;
		align-items: center;
		padding: 19px 45px;
		@include layout(767) {
			width: 140px;
			height: 40px;
			padding: 10px 13px;
		}
	}
}

.get-touch-text {
	color: rgba(255, 255, 255, 0.9);
	font-size: 18px;
	padding-right: 15px;
	@include layout(767) {
		font-size: 14px;
	}
}

.intelliscan-section {
	margin-top: 60px;
	h1 {
		color: #172899;
		font-size: 36px;
		font-family: $rob-reg;
		display: flex;
		justify-content: center;
		position: relative;
		@include layout(767) {
			font-size: 20px;
			margin-top: 100px;
		}
		&:after {
			content: "";
			position: absolute;
			background: #ff6600;
			height: 4px;
			width: 100px;
			left: 0;
			right: 0;
			margin: 0 auto;
			bottom: -6px;
			@include layout(767) {
				width: 50px;
				height: 2px;
			}
		}
	}
	@include layout(767) {
		padding: 0px 20px;
	}
}

.intelliscan-block-sections {
	padding-top: 68px;
	@include layout(1023) {
		flex-direction: column;
	}
	@include layout(767) {
		.slick-dots li button::before {
			content: "" !important;
			border-radius: 50%;
			background-color: #414141;
			width: 12px !important;
			height: 11px !important;
		}
		.slick-dots {
			bottom: 40px !important;
		}
	}
}

.intelliscan-block {
	width: 33.3%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-right: 63px;
	@include layout(1150) {
		padding-right: 50px;
	}
	@include layout(1150) {
		padding-right: 20px;
	}
	@include layout(767) {
		width: 100%;
		justify-content: center;
		padding-right: 0;
		padding-bottom: 50px;
	}
}

.block-title {
	color: #3a4146;
	font-size: 18px;
	font-family: $lat-sbld;
	padding: 14px 0px 10px 0px;
	text-align: center;
}

.block-content {
	color: #7b7c7c;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	width: 100%;
}

.intelliscan-block:nth-child(3n) {
	padding-right: 0px;
}

.intelliscan-block-section {
	max-width: 952px;
	align-items: baseline;
	width: 100%;
	padding-bottom: 50px;
	margin: 0 auto;
	display: flex;
	.intelliscan-block {
		padding-left: 63px;
		@include layout(1100) {
			padding-left: 50px;
		}
		@include layout(1023) {
			padding-left: 20px;
		}
	}
	.intelliscan-block:first-child {
		padding-left: 0px;
	}
	@include layout(767) {
		flex-direction: column;
		width: 100%;
		justify-content: center;
	}
}

.block-img {
	img{
		height: 70px;
	}
	// img.weight70 {
	// 	height: 70px;
	// }
	// img.height80 {
	// 	height: 70px;
	// }
	// img.inerface {
	// 	width: 85px;
	// }
	// img.router {
	// 	height: 65px;
	// }
	// img.capability {
	// 	height: 80px;
	// }
}

.team-section {
	margin-top: 120px;
	@include layout(767) {
		padding: 0px 20px;
		margin-top: 105px;
	}
	.teams {
		color: #172899;
		font-size: 36px;
		font-family: $rob-reg;
		display: flex;
		justify-content: center;
		position: relative;
		@include layout(767) {
			font-size: 20px;
		}
		&:after {
			content: "";
			position: absolute;
			background: #ff6600;
			width: 100px;
			left: 0;
			height: 4px;
			right: 0;
			margin: 0 auto;
			bottom: -6px;
			@include layout(767) {
				width: 50px;
				height: 2px;
			}
		}
	}
}

.title-of-team {
	color: #7b7c7c;
	font-size: 16px;
	max-width: 526px;
	line-height: 22px;
	text-align: center;
	margin: 57px auto 67px auto;
}

.team-profiles-section {
	display: flex;
	max-width: 1000px;
	width: 100%;
	margin: 0 auto;
	justify-content: space-between;
	@include layout(1023) {
		flex-direction: column;
	}
}

.team-profile {
	display: flex;
	padding-right: 20px;
	&:last-child {
		padding-right: 0px;
		@include layout(1023) {
			margin-top: 50px;
		}
	}
	@include layout(1023) {
		flex-direction: column;
		padding-right: 0px;
	}
}

.team-member-block {
	display: flex;
	flex-direction: column;
	padding-right: 20px;
	/* justify-content: center; */
	line-height: 24px;
	align-items: center;
	border-right: 1px solid rgba(123, 124, 124, 0.7);
	@include layout(1023) {
		border-right: none;
	}
	h1 {
		color: #333333;
		font-size: 18px;
		font-family: $lat-sbld;
		@include layout(1023) {
			padding-top: 10px;
		}
	}
	span {
		color: #7b7c7c;
		font-size: 16px;
	}
}

.team-member-information {
	margin-left: 20px;
	max-width: 230px;
	width: 100%;
	color: #7b7c7c;
	align-self: center;
	font-size: 13px;
	line-height: 20px;
	@include layout(1023) {
		max-width: 600px;
		margin: 15px auto;
	}
}

.wave-img {
	position: absolute;
	top: -25px;
	right: -45px;
	@include layout(767) {
		display: none;
	}
}