@font-face {
  font-family: 'lato-light';
  src: url("../fonts/lato-light.ttf");
}

@font-face {
  font-family: 'lato-thin';
  src: url("../fonts/lato-thin.ttf");
}

@font-face {
  font-family: 'lato-medium';
  src: url("../fonts/lato-medium.ttf");
}

@font-face {
  font-family: 'lato-semibold';
  src: url("../fonts/lato-semibold.ttf");
}

@font-face {
  font-family: 'lato-regular';
  src: url("../fonts/lato-regular.ttf");
}

@font-face {
  font-family: 'lato-bold';
  src: url("../fonts/lato-bold.ttf");
}

@font-face {
  font-family: 'RobotoSlab-Light';
  src: url("../fonts/RobotoSlab-Light.ttf");
}

@font-face {
  font-family: 'RobotoSlab-Thin';
  src: url("../fonts/RobotoSlab-Thin.ttf");
}

@font-face {
  font-family: 'RobotoSlab-Regular';
  src: url("../fonts/RobotoSlab-Regular.ttf");
}

@font-face {
  font-family: 'RobotoSlab-Bold';
  src: url("../fonts/RobotoSlab-Bold.ttf");
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

select, input,
option,
textarea {
  font-family: "lato-regular";
  font-size: 12px;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  font-family: "lato-regular";
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  margin: 0;
  padding: 0;
  background-color: #fff !important;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.button {
  width: 100%;
  height: 45px;
  line-height: 45px;
  padding: 0 10px;
  background: #192431;
  font-family: "lato-regular";
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  border: none;
  color: #fff;
  cursor: pointer;
}

.button:focus {
  outline: none;
}

/*reset inputs*/
input,
select,
button {
  -webkit-tap-highlight-color: transparent;
}

input:focus,
select:focus,
button:focus {
  outline: none;
}

input[type="text"],
input[type="password"] {
  font-family: "lato-regular";
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  border: none;
  width: 100%;
  background: transparent;
}

input[type="text"]:focus,
input[type="password"]:focus {
  outline: none;
}

textarea {
  resize: none;
}

textarea:focus {
  outline: none;
}

.error {
  display: none;
  color: #ff0000;
  font-size: 12px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

svg {
  max-width: 100%;
  max-height: 100%;
  vertical-align: top;
}

a {
  text-decoration: none;
  font-family: "lato-regular";
  color: #7c7c7c;
}

a:focus {
  outline: none;
}

a img {
  border: none;
  outline: none;
}

input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

select {
  appearance: none;
}

select::-ms-expand {
  display: none;
}

/* common styles start */
.full-width {
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
}

.restrict-width {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
}

.padding-lr {
  padding: 0 40px;
}

@media (max-width: 1024px) {
  .padding-lr {
    padding: 0 30px;
  }
}

@media (max-width: 1023px) {
  .padding-lr {
    padding: 0 20px;
  }
}

@media (max-width: 767px) {
  .padding-lr {
    padding: 0 0px;
  }
}

.mr-left-0 {
  margin-left: 0 !important;
}

.mr-right-0 {
  margin-right: 0 !important;
}

.pad-left-0 {
  padding-left: 0 !important;
}

.pad-right-0 {
  padding-right: 0 !important;
}

.verticalM {
  display: inline-block;
  vertical-align: middle;
}

.verticalT {
  display: inline-block;
  vertical-align: top;
}

.verticalB {
  display: inline-block;
  vertical-align: bottom;
}

.hide {
  display: none;
}

.show {
  display: inline-block;
}

.v-hidden {
  visibility: hidden;
}

.v-show {
  visibility: visibility;
}

button {
  appearance: none;
  outline: none;
  border: none;
  font-family: inherit;
  line-height: 1;
  cursor: pointer;
}

.buttons-block {
  display: flex;
}

.btn-fill {
  color: #fff;
  font-size: 14px;
  background-color: #71afe0;
  padding: 12px 35px;
  border-radius: 4px;
}

.btn-text {
  color: #71afe0;
  font-size: 14px;
  background-color: transparent;
  margin-left: 20px;
}

/* custom checkbox styles start */
.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
}

.checkbox-block {
  margin-bottom: 20px;
  width: 250px;
}

.checkbox-block span {
  display: inherit;
  padding-left: 26px;
  color: #929ba5;
  font-size: 12px;
}

.checkbox-label {
  color: #504b4a;
  font-size: 16px;
}

input[type="checkbox"] {
  width: inherit;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.6em;
  cursor: pointer;
  line-height: 14px;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: url("../images/checkbox-1.svg");
  position: absolute;
  left: 0;
  top: 0;
  width: 14px;
  height: 14px;
  /* width: 1.25em; height: 1.25em;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1); */
}

/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: url("../images/checkbox-2.svg");
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  transition: all .2s;
}

/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

/* custom checkbox styles end */
/* common styles end */
.m-t-hide {
  display: block;
}

@media (max-width: 1023px) {
  .m-t-hide {
    display: none;
  }
}

.m-t-show {
  display: none;
}

@media (max-width: 1023px) {
  .m-t-show {
    display: block;
  }
}

.common-footer {
  overflow: hidden;
}

/* header styles start */
.logo-and-menu-section {
  padding: 30px 70px 0px 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .logo-and-menu-section {
    padding: 30px 50px 0px 20px;
  }
}

.logo-and-menu-section .header-right-sdie-login-button {
  z-index: 5;
}

.logo-and-menu-section .get-touch-button {
  margin-top: 0;
}

.logo-and-menu-section .get-touch-button a {
  padding: 12px 25px;
  width: auto;
  height: auto;
}

@media (max-width: 767px) {
  .logo-and-menu-section .get-touch-button a {
    padding: 10px 20px;
  }
}

.logo-and-menu-section .get-touch-text {
  padding-right: 0px;
}

.header-logo-section img {
  height: 100px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .header-logo-section img {
    width: 85px;
    height: 51px;
  }
}

/* header styles end */
.get-touch-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: -50px;
  width: 100%;
  padding: 0px 80px;
}

@media (max-width: 1280px) {
  .get-touch-section {
    padding: 0px 50px;
  }
}

@media (max-width: 1023px) {
  .get-touch-section {
    flex-wrap: wrap-reverse;
    position: initial;
    justify-content: center;
    padding: 0px 30px;
  }
}

@media (max-width: 767px) {
  .get-touch-section {
    padding: 0px;
  }
}

@media (max-width: 767px) {
  .para-header {
    font-size: 20px;
  }
}

.arrow-button img {
  width: 14px;
  height: 12px;
}

.img-section {
  overflow: hidden;
}

@media (max-width: 767px) {
  .img-section img {
    width: 125%;
    margin-left: -41px;
  }
}

@media (max-width: 420px) {
  .img-section img {
    width: 120%;
  }
}

.text-and-button-section {
  position: relative;
}

@media (max-width: 767px) {
  .text-and-button-section {
    text-align: center;
    padding: 0px 20px;
  }
}

.fingertrips-text-desktop {
  color: #172899;
  font-size: 24px;
  font-family: "RobotoSlab-Regular";
}

@media (max-width: 767px) {
  .fingertrips-text-desktop {
    display: none;
  }
}

.fingertrips-text-mobile {
  color: #172899;
  font-size: 16px;
  font-family: "RobotoSlab-Regular";
}

@media (min-width: 767px) {
  .fingertrips-text-mobile {
    display: none;
  }
}

.intelliscan-title-text-desktop {
  color: #172899;
  font-size: 55px;
  font-family: "RobotoSlab-Regular";
}

.intelliscan-title-text-desktop b {
  color: #ff6600;
}

@media (max-width: 767px) {
  .intelliscan-title-text-desktop {
    display: none;
  }
}

.intelliscan-title-text-mobile {
  color: #172899;
  font-size: 23px;
  font-family: "RobotoSlab-Regular";
}

.intelliscan-title-text-mobile b {
  color: #ff6600;
}

@media (min-width: 767px) {
  .intelliscan-title-text-mobile {
    display: none;
  }
}

.intelliscan-sub-text-desktop {
  color: #636469;
  font-size: 20px;
  padding-top: 2px;
}

@media (max-width: 767px) {
  .intelliscan-sub-text-desktop {
    display: none;
  }
}

.intelliscan-sub-text-mobile {
  color: #636469;
  font-size: 14px;
  padding-top: 2px;
}

@media (min-width: 767px) {
  .intelliscan-sub-text-mobile {
    display: none;
  }
}

.get-touch-button {
  border-radius: 6px;
  background-color: #172899;
  margin-top: 30px;
  cursor: pointer;
  display: inline-block;
}

.get-touch-button a {
  display: flex;
  align-items: center;
  padding: 19px 45px;
}

@media (max-width: 767px) {
  .get-touch-button a {
    width: 140px;
    height: 40px;
    padding: 10px 13px;
  }
}

.get-touch-text {
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;
  padding-right: 15px;
}

@media (max-width: 767px) {
  .get-touch-text {
    font-size: 14px;
  }
}

.intelliscan-section {
  margin-top: 60px;
}

.intelliscan-section h1 {
  color: #172899;
  font-size: 36px;
  font-family: "RobotoSlab-Regular";
  display: flex;
  justify-content: center;
  position: relative;
}

@media (max-width: 767px) {
  .intelliscan-section h1 {
    font-size: 20px;
    margin-top: 100px;
  }
}

.intelliscan-section h1:after {
  content: "";
  position: absolute;
  background: #ff6600;
  height: 4px;
  width: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -6px;
}

@media (max-width: 767px) {
  .intelliscan-section h1:after {
    width: 50px;
    height: 2px;
  }
}

@media (max-width: 767px) {
  .intelliscan-section {
    padding: 0px 20px;
  }
}

.intelliscan-block-sections {
  padding-top: 68px;
}

@media (max-width: 1023px) {
  .intelliscan-block-sections {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .intelliscan-block-sections .slick-dots li button::before {
    content: "" !important;
    border-radius: 50%;
    background-color: #414141;
    width: 12px !important;
    height: 11px !important;
  }
  .intelliscan-block-sections .slick-dots {
    bottom: 40px !important;
  }
}

.intelliscan-block {
  width: 33.3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 63px;
}

@media (max-width: 1150px) {
  .intelliscan-block {
    padding-right: 50px;
  }
}

@media (max-width: 1150px) {
  .intelliscan-block {
    padding-right: 20px;
  }
}

@media (max-width: 767px) {
  .intelliscan-block {
    width: 100%;
    justify-content: center;
    padding-right: 0;
    padding-bottom: 50px;
  }
}

.block-title {
  color: #3a4146;
  font-size: 18px;
  font-family: "lato-semibold";
  padding: 14px 0px 10px 0px;
  text-align: center;
}

.block-content {
  color: #7b7c7c;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  width: 100%;
}

.intelliscan-block:nth-child(3n) {
  padding-right: 0px;
}

.intelliscan-block-section {
  max-width: 952px;
  align-items: baseline;
  width: 100%;
  padding-bottom: 50px;
  margin: 0 auto;
  display: flex;
}

.intelliscan-block-section .intelliscan-block {
  padding-left: 63px;
}

@media (max-width: 1100px) {
  .intelliscan-block-section .intelliscan-block {
    padding-left: 50px;
  }
}

@media (max-width: 1023px) {
  .intelliscan-block-section .intelliscan-block {
    padding-left: 20px;
  }
}

.intelliscan-block-section .intelliscan-block:first-child {
  padding-left: 0px;
}

@media (max-width: 767px) {
  .intelliscan-block-section {
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }
}

.block-img img {
  height: 70px;
}

.team-section {
  margin-top: 120px;
}

@media (max-width: 767px) {
  .team-section {
    padding: 0px 20px;
    margin-top: 105px;
  }
}

.team-section .teams {
  color: #172899;
  font-size: 36px;
  font-family: "RobotoSlab-Regular";
  display: flex;
  justify-content: center;
  position: relative;
}

@media (max-width: 767px) {
  .team-section .teams {
    font-size: 20px;
  }
}

.team-section .teams:after {
  content: "";
  position: absolute;
  background: #ff6600;
  width: 100px;
  left: 0;
  height: 4px;
  right: 0;
  margin: 0 auto;
  bottom: -6px;
}

@media (max-width: 767px) {
  .team-section .teams:after {
    width: 50px;
    height: 2px;
  }
}

.title-of-team {
  color: #7b7c7c;
  font-size: 16px;
  max-width: 526px;
  line-height: 22px;
  text-align: center;
  margin: 57px auto 67px auto;
}

.team-profiles-section {
  display: flex;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
}

@media (max-width: 1023px) {
  .team-profiles-section {
    flex-direction: column;
  }
}

.team-profile {
  display: flex;
  padding-right: 20px;
}

.team-profile:last-child {
  padding-right: 0px;
}

@media (max-width: 1023px) {
  .team-profile:last-child {
    margin-top: 50px;
  }
}

@media (max-width: 1023px) {
  .team-profile {
    flex-direction: column;
    padding-right: 0px;
  }
}

.team-member-block {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  /* justify-content: center; */
  line-height: 24px;
  align-items: center;
  border-right: 1px solid rgba(123, 124, 124, 0.7);
}

@media (max-width: 1023px) {
  .team-member-block {
    border-right: none;
  }
}

.team-member-block h1 {
  color: #333333;
  font-size: 18px;
  font-family: "lato-semibold";
}

@media (max-width: 1023px) {
  .team-member-block h1 {
    padding-top: 10px;
  }
}

.team-member-block span {
  color: #7b7c7c;
  font-size: 16px;
}

.team-member-information {
  margin-left: 20px;
  max-width: 230px;
  width: 100%;
  color: #7b7c7c;
  align-self: center;
  font-size: 13px;
  line-height: 20px;
}

@media (max-width: 1023px) {
  .team-member-information {
    max-width: 600px;
    margin: 15px auto;
  }
}

.wave-img {
  position: absolute;
  top: -25px;
  right: -45px;
}

@media (max-width: 767px) {
  .wave-img {
    display: none;
  }
}

.desc-comp-one {
  text-align: center;
  margin: 0 auto;
  max-width: 900px;
  width: 100%;
  margin-top: 60px;
  line-height: 23px;
}

@media (max-width: 1023px) {
  .desc-comp-one {
    padding: 0px 30px;
  }
}

@media (max-width: 767px) {
  .desc-comp-one {
    margin-top: 100px;
    padding: 0px 20px;
  }
}

.desc-comp-one .para-header {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .desc-comp-one .para-header {
    font-size: 20px;
  }
}

.desc-comp-one hr {
  margin: auto;
  background: #ff6600;
  margin-top: 1px;
  width: 100px;
  height: 4px;
  border: 1px solid #ff6600;
  margin-bottom: 55px;
}

@media (max-width: 767px) {
  .desc-comp-one hr {
    width: 50px;
    margin-bottom: 20px;
    height: 2px;
  }
}

.desc-comp-one .text-block-desktop {
  color: #7b7c7c;
  font-size: 16px;
  font-family: "lato-regular";
}

@media (max-width: 767px) {
  .desc-comp-one .text-block-desktop {
    display: none;
  }
}

.desc-comp-one .text-block-mobile {
  color: #7b7c7c;
  font-size: 14px;
  font-family: "lato-regular";
  padding: 0px 20px;
}

@media (min-width: 767px) {
  .desc-comp-one .text-block-mobile {
    display: none;
  }
}

.para-header {
  color: #172899;
  font-size: 36px;
  font-family: "RobotoSlab-Regular";
}

.desc-comp-two {
  text-align: center;
  position: relative;
  margin: 60px auto 0px auto;
  padding-top: 115px;
  padding-left: 15%;
  padding-right: 15%;
  background-size: 140%;
  overflow: hidden;
  padding-bottom: 130px;
  background-repeat: no-repeat;
  background-position: center;
  line-height: 23px;
}

@media (max-width: 1250px) {
  .desc-comp-two {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media (max-width: 1200px) {
  .desc-comp-two {
    background-size: 170%;
  }
}

@media (max-width: 850px) {
  .desc-comp-two {
    background-size: 180%;
  }
}

@media (max-width: 767px) {
  .desc-comp-two {
    margin: 100px 0px 60px 0px;
    padding: 65px 20px;
  }
}

.desc-comp-two .para-header {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .desc-comp-two .para-header {
    font-size: 20px;
  }
}

.desc-comp-two hr {
  margin: auto;
  background-color: #ff6600;
  width: 100px;
  border: 1px solid #ff6600;
  height: 4px;
  margin-top: 1px;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .desc-comp-two hr {
    width: 50px;
    margin-bottom: 20px;
    height: 2px;
  }
}

.desc-comp-two .text-header {
  font-family: "lato-regular";
  color: #333232;
  font-size: 24px;
  margin-bottom: 15px;
  position: relative;
  left: -15px;
  text-align: left;
}

@media (max-width: 767px) {
  .desc-comp-two .text-header {
    font-size: 18px;
  }
}

.desc-comp-two ul {
  text-align: left;
  list-style: disc;
  margin-bottom: 18px;
  line-height: 26px;
}

.desc-comp-two .details-block {
  display: flex;
  justify-content: space-between;
  color: #7b7c7c;
  font-size: 16px;
  font-family: "lato-regular";
  align-items: center;
}

@media (max-width: 767px) {
  .desc-comp-two .details-block {
    font-size: 14px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

@media (max-width: 767px) {
  .desc-comp-two .details-block .image-block img {
    width: 100%;
  }
}

.desc-comp-two .details-block:nth-child(even) {
  margin-top: 25px;
}

@media (min-width: 767px) {
  .desc-comp-two .details-block:nth-child(even) {
    flex-direction: row-reverse;
  }
}

.desc-comp-two .list-block {
  margin-right: 40px;
}

@media (max-width: 1023px) {
  .desc-comp-two .list-block {
    margin-left: 30px;
  }
}

@media (max-width: 767px) {
  .desc-comp-two .list-block {
    margin: 15px 0px 0px 20px;
    align-self: baseline;
  }
}

.desc-comp-two .image-block {
  margin-left: -30px;
}

@media (max-width: 1023px) {
  .desc-comp-two .image-block {
    width: 50%;
  }
  .desc-comp-two .image-block img {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .desc-comp-two .image-block {
    width: 100%;
    margin-left: 0px;
  }
}

.common-footer-block {
  display: flex;
  background: linear-gradient(180deg, #eaedff 0%, #e6e7ef 100%);
  justify-content: space-between;
  max-height: 450px;
  position: relative;
}

.contact-us-block {
  padding: 70px 0px 0px 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media (max-width: 767px) {
  .contact-us-block {
    padding: 50px 20px 0px 20px;
  }
}

.contact-us-block .text-header {
  color: #383838;
  font-size: 22px;
  font-weight: 600;
  line-height: 22.934px;
  text-align: left;
}

@media (max-width: 767px) {
  .contact-us-block .text-header {
    font-size: 16px;
  }
}

.contact-us-block .social-icons-block-in-footer {
  display: flex;
  margin-top: 30px;
}

.contact-us-block .each-social-block {
  width: 36px;
  cursor: pointer;
  height: 36px;
  margin-right: 30px;
}

.contact-us-block .each-social-block img {
  border-radius: 50%;
  width: 100%;
}

.contact-us-block .para-header {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .contact-us-block .para-header {
    font-size: 18px;
  }
}

.contact-us-block .text-block {
  font-family: Lato;
  color: #383838;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

@media (max-width: 767px) {
  .contact-us-block .text-block {
    font-size: 14px;
    line-height: 20px;
  }
}

.contact-us-block .under-line {
  background: #ff6600;
  height: 4px;
  margin-top: 1px;
  width: 100px;
  border: 1px solid #ff6600;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .contact-us-block .under-line {
    margin-bottom: 20px;
    width: 50px;
    height: 2px;
  }
}

.map-block {
  width: 50%;
}

.map-block img {
  border-top-left-radius: 70%;
  float: right;
}

@media (max-width: 767px) {
  .map-block {
    display: none;
  }
}

.design-brand {
  margin-top: 30px;
  font-size: 12px;
  margin-bottom: 20px;
}

.design-brand a {
  padding-left: 5px;
}

.divami-logo {
  width: 45px;
  margin-top: 10px;
}

.main-testimonal {
  overflow: hidden;
  margin: 140px auto;
  position: relative;
}

@media (max-width: 767px) {
  .main-testimonal {
    padding: 35px 20px 60px 20px;
    margin-bottom: 65px;
    margin-top: 90px;
  }
}

.background-shadow-in-mobile {
  position: absolute;
  z-index: -1;
  height: 98%;
  background: linear-gradient(180deg, #eaedff 0%, #e6e7ef 100%);
  width: 1920px;
  top: 0;
  border-radius: 50%;
  left: calc((100% - 1920px) / 2);
  background-position: center;
  display: block;
}

@media (max-width: 767px) {
  .background-shadow-in-mobile {
    transform: rotate(0deg);
    height: 100%;
  }
}

.testimonal-block {
  padding-top: 115px;
  padding-bottom: 85px;
  text-align: center;
  margin: 40px auto;
  line-height: 23px;
  max-width: 450PX;
  width: 100%;
}

@media (max-width: 767px) {
  .testimonal-block {
    margin: 20px auto;
    padding: 30px 0px;
  }
}

.testimonal-block .para-header {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .testimonal-block .para-header {
    font-size: 20px;
  }
}

.testimonal-block hr {
  margin: auto;
  background: #ff6600;
  margin-top: 1px;
  width: 100px;
  height: 4px;
  margin-bottom: 60px;
  border: 1px solid #ff6600;
}

@media (max-width: 767px) {
  .testimonal-block hr {
    height: 2px;
  }
}

.testimonal-block .quotationmark-block-one {
  margin-left: -40px;
  text-align: left;
}

.testimonal-block .quotationmark-block-one img {
  width: 24px;
}

@media (max-width: 580px) {
  .testimonal-block .quotationmark-block-one {
    margin-left: 0px;
  }
}

.testimonal-block .quotationmark-block-two {
  margin-right: -40px;
  text-align: right;
}

.testimonal-block .quotationmark-block-two img {
  width: 24px;
}

@media (max-width: 580px) {
  .testimonal-block .quotationmark-block-two {
    margin-right: 0px;
  }
}

.testimonal-block .text-block {
  font-family: "RobotoSlab-Regular";
  color: #333333;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
}

@media (max-width: 767px) {
  .testimonal-block .text-block {
    font-size: 14px;
  }
}

@media (max-width: 580px) {
  .testimonal-block .text-block {
    font-size: 14px;
    padding: 0px 25px;
  }
}

.testimonal-block .person-name-block {
  font-family: "lato-bold";
  color: #333333;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}

.testimonal-block .person-job {
  font-family: "lato-regular";
  color: #7c7b7b;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.user-profiles-blocks {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.user-profiles-blocks .slick-current {
  width: 77px !important;
  height: 77px;
}

.user-img-wapper {
  width: 55px !important;
  height: 55px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 40px;
}

.user-img-wapper:last-child {
  margin-right: 0px;
}

.user-img-wapper.active {
  width: 79px !important;
  height: 79px;
  border: 1px solid #ff6600;
}

.user-img-wapper img {
  width: 100%;
  border-radius: 50%;
}

.how-it-works {
  text-align: center;
  margin-top: 73px;
}

@media (max-width: 767px) {
  .how-it-works {
    margin-top: 57px;
    padding-top: 63px;
    padding-bottom: 65px;
    position: relative;
    overflow: hidden;
  }
  .how-it-works::after {
    position: absolute;
    z-index: -1;
    content: "";
    height: 100%;
    background: linear-gradient(180deg, #eaedff 0%, #e6e7ef 100%);
    width: 1920px;
    top: 0;
    border-radius: 50%;
    left: calc((100% - 1920px) / 2);
    background-position: center;
    display: block;
  }
}

.how-it-works hr {
  margin: auto;
  background: #ff6600;
  width: 100px;
  height: 4px;
  margin-top: 1px;
  border: 1px solid #ff6600;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .how-it-works hr {
    width: 50px;
    margin-bottom: 20px;
    height: 2px;
  }
}

.how-it-works .work-flow-block {
  display: flex;
  justify-content: center;
  margin-top: 90px;
  width: 100%;
}

@media (max-width: 1023px) {
  .how-it-works .work-flow-block {
    flex-direction: column;
    margin-top: 40px;
  }
}

.how-it-works .work-flow-block .work1 {
  margin-top: -45px;
  max-width: 13%;
}

@media (max-width: 1023px) {
  .how-it-works .work-flow-block .work1 {
    margin: 0 auto;
    max-width: 100%;
  }
}

.how-it-works .work-flow-block .work2 {
  max-width: 13%;
}

@media (max-width: 1023px) {
  .how-it-works .work-flow-block .work2 {
    margin: 0 auto;
    max-width: 100%;
  }
}

.how-it-works .work-flow-block .work3 {
  margin-top: -57px;
  max-width: 13%;
}

@media (max-width: 1023px) {
  .how-it-works .work-flow-block .work3 {
    margin: 0 auto;
    max-width: 100%;
  }
}

.how-it-works .work-flow-block .work4 {
  margin-top: -25px;
  max-width: 13%;
}

@media (max-width: 1023px) {
  .how-it-works .work-flow-block .work4 {
    margin: 0 auto;
    max-width: 100%;
  }
}

.how-it-works .work-flow-block .path1 {
  display: flex;
  align-items: center;
}

@media (max-width: 1023px) {
  .how-it-works .work-flow-block .path1 {
    margin: 10px auto;
    transform: rotateZ(75deg);
    padding: 60px 0;
  }
}

.how-it-works .work-flow-block .path2 {
  display: flex;
  align-items: baseline;
}

@media (max-width: 1023px) {
  .how-it-works .work-flow-block .path2 {
    margin: 0 auto;
    transform: rotate(80deg);
    padding: 45px 0px;
    margin-top: 10px;
  }
}

.how-it-works .work-flow-block .path3 {
  display: flex;
  align-items: center;
  padding-bottom: 100px;
}

@media (max-width: 1023px) {
  .how-it-works .work-flow-block .path3 {
    margin: 10px auto 0px auto;
    padding-bottom: 0px;
  }
}

@media (max-width: 1023px) {
  .how-it-works .para-header {
    font-size: 20px;
  }
}
