.how-it-works {
	text-align: center;
	margin-top: 73px;
	@include layout(767) {
		margin-top: 57px;
		padding-top: 63px;
		padding-bottom: 65px;
		position: relative;
		overflow: hidden;
		&::after{
			position: absolute;
			z-index: -1;
			content: "";
			height: 100%;
			background: linear-gradient(180deg, #eaedff 0%, #e6e7ef 100%);
			width: 1920px;
			top: 0;
			border-radius: 50%;
			left:calc((100% - 1920px) / 2);
			background-position: center;
			display: block;
		}
	}
	hr {
		margin: auto;
		background: #ff6600;
		width: 100px;
		height: 4px;
		margin-top: 1px;
		border: 1px solid #ff6600;
		margin-bottom: 60px;
		@include layout(767) {
			width: 50px;
			margin-bottom: 20px;
			height: 2px;
		}
	}
	.work-flow-block {
		display: flex;
		justify-content: center;
		margin-top: 90px;
		width: 100%;
		@include layout(1023) {
			flex-direction: column;
			margin-top: 40px;
		}
		.work1 {
			margin-top: -45px;
			max-width: 13%;
			@include layout(1023) {
				margin: 0 auto;
				max-width: 100%;
			}
		}
		.work2 {
			max-width: 13%;
			@include layout(1023) {
				margin: 0 auto;
				max-width: 100%;
			}
		}
		.work3 {
			margin-top: -57px;
			max-width: 13%;
			@include layout(1023) {
				margin: 0 auto;
				max-width: 100%;
			}
		}
		.work4 {
			margin-top: -25px;
			max-width: 13%;
			@include layout(1023) {
				margin: 0 auto;
				max-width: 100%;
			}
		}
		.path1 {
			display: flex;
			align-items: center;
			@include layout(1023) {
				margin: 10px auto;
				transform: rotateZ(75deg);
				padding: 60px 0;
			}
		}
		.path2 {
			display: flex;
			align-items: baseline;
			@include layout(1023) {
				margin: 0 auto;
				transform: rotate(80deg);
				padding: 45px 0px;
				margin-top: 10px;
			}
		}
		.path3 {
			display: flex;
			align-items: center;
			padding-bottom: 100px;
			@include layout(1023) {
				margin: 10px auto 0px auto;
				padding-bottom: 0px;
			}
		}
	}
	.para-header {
		@include layout(1023) {
			font-size: 20px;
		}
	}
}